import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
// import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
// import Toolbar from '@material-ui/core/Toolbar';
// import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { 
        Box,
        ListSubheader, 
        Paper, 
        // TextField,
        // Menu,
        // MenuItem,
        // CardActions,
        // Button
    } from '@material-ui/core';

// import { KeyboardDatePicker } from '@material-ui/pickers';

import PropTypes from 'prop-types';
// import SwipeableViews from 'react-swipeable-views';
// import Tabs from '@material-ui/core/Tabs';
// import Tab from '@material-ui/core/Tab';

//import Table from '@material-ui/core/Table';
// import TableBody from '@material-ui/core/TableBody';
// import TableCell from '@material-ui/core/TableCell';
// import TableContainer from '@material-ui/core/TableContainer';
// import TableHead from '@material-ui/core/TableHead';
// import TablePagination from '@material-ui/core/TablePagination';
// import TableRow from '@material-ui/core/TableRow';

// import Switch from '@material-ui/core/Switch';
// import FormGroup from '@material-ui/core/FormGroup';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import FormControl from '@material-ui/core/FormControl';

import IconButton from '@material-ui/core/IconButton';
// import Badge from '@material-ui/core/Badge';
import Card from '@material-ui/core/Card';
// import CardActions from '@material-ui/core/CardActions';
// import CardContent from '@material-ui/core/CardContent';
// import Button from '@material-ui/core/Button';

// import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import AccountBalanceIcon from '@material-ui/icons/AccountBalanceOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import PhotoLibraryOutlinedIcon from '@material-ui/icons/PhotoLibraryOutlined';
import DeleteIcon from '@material-ui/icons/Delete';
import AddPhotoAlternateOutlinedIcon from '@material-ui/icons/AddPhotoAlternateOutlined';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
// import { Grid } from '@material-ui/core';

// Import from AntDesign UI
import { Modal, Popconfirm} from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';


// Project Components
import TabPanel from './TabPanel';
import TabExhibit from './TabExhibit';
import ModalAbout from './ModalAbout';
import ModalSettings  from './ModalSettings';
import ModalNewExhibit from './ModalNewExhibit';
// import ThreeContainer from './ThreeContainer';
import ThreeJSComponent from './ThreeJSComponent';
import * as Utils from './Utils';

// import Copyright  from './Copyright';
import logo from './logo.png';
import './App.css'
import firebase from './firebase'
// import { isFirstDayOfMonth } from 'date-fns';
import Login from './Login';
import { AuthProvider } from './Contexts/AuthContext';
import { useAuth } from './Contexts/AuthContext'
import { useHistory } from "react-router-dom";
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

const { confirm } = Modal;

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    background: 'lightslategray',
    height: '100%',
    minHeight: '100vh'
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: 'auto',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
    background: 'lightslategray'
  },
  title: {
    flexGrow: 1,
    fontSize: 14,
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
    backgroundColor: 'white',
    color: 'gray'
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  AppLogo: {
    width: 200,
  },
  nested: {
    paddingLeft: theme.spacing(3),
  },
  listTitle: {
    fontWeight: 'bold',
    color:'dimgray',
    fontSize: '16px',
  },
  card: {
    minWidth: 275,
  },
  tabGeneral: {
    display: 'block',
  },
  ListItemSubText: { 
    fontSize:'8px !important',
  }
}));


TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  

/**
 * Main App Component
 * @returns 
 */
export default function MainApp() {

    // Initialize Style to them the components
    const classes = useStyles();

    // Modals States
    const [openModalAbout,      setModalAbout]      = useState(false);
    const [openModalSettings,   setModalSettings]   = useState(false);
    const [openModalNewExhibit, setOpenModalNewExhibit] = useState(false);

    //
    function handleCloseModalNewExhibit(){
      setOpenModalNewExhibit(false);
    }


    //
    function handleModalNewExhibitAnswer(value){
      // console.log("Event", value);
    }


    //
    function createNewExhibit(data){

      // Debug
      // console.log("Creating new exhibit");
      // console.log(data);

      const imageData = data.posterData;

      delete data.posterData;

      firebase.firestore().collection("Exhibitions").add(data)
            .then((docRef) => {
                Utils.NotifyUserSuccess('Notificación','La exposición ha sido creada correctamente.');

                // Debug
                // console.log("Document written with ID: ", docRef.id);

                // Upload the imagen to the FireStore 
                if(imageData){
                  uploadImage(docRef.id, imageData)
                  .then( imgURL => {
                    // console.log(imgURL)
                    var pexhibit = firebase.firestore().collection("Exhibitions").doc(docRef.id);
                    pexhibit.update({poster: imgURL});
                  } )
                }

            })
            .catch((error) => {
                Utils.NotifyUserError('Notificación','La exposición no ha podido ser creada.');
                console.error("Error adding document: ", error);
            });
    }


    // Control Selected Exhibit
    const [selectedExhibit, setSelectedExhibit] = useState(null);
    const [refresh, setRefresh]    = useState();
    const [title, setTitle]        = useState('Test');
    const [editMode, setEditMode]  = useState(false);


    function showConfirmCancelUpdate(exhibit) {
      confirm({
        title: 'Quiere cancelar la edición?',
        icon: <ExclamationCircleOutlined />,
        content: 'Esta editando ahora mismo la exposición:' + selectedExhibit.title + ', Esta seguro que quiere cancelar la edición?' ,
        okText: 'Aceptar',
        cancelText: 'Cancelar',
        onOk() {
          cancelUpdateExhibit();
          setSelectedExhibit(exhibit);
          setTitle(exhibit.title);
        },
        onCancel() {
          // Nothing to do here yet
        },
      });
    }

    function exhibitClick(event, exhibit){

      if(editMode){
        showConfirmCancelUpdate(exhibit);
      }else{
        // Debug
        // console.log("Selecting: ", exhibit);
        setSelectedExhibit(exhibit);
        setTitle(exhibit.title);
      }
    }

    function selectExhibitByID(exhibitID){

      setSelectedExhibit(null);

      for (var i = 0; i < exhbitions.length; i++) {

          if(  strcmp(exhbitions[i].id, exhibitID) == 0 ) 
          {

            console.log("Updating Selected ", exhbitions[i].id, " :: ", exhibitID);

            setSelectedExhibit(exhbitions[i]);
            setTitle(exhbitions[i].title);
            return;
          }
      }

    }


    /**
     * 
     */
    function strcmp(a, b)
    {   
        return (a<b?-1:(a>b?1:0));  
    }


    /**
     * 
     */
    function deleteFolderContents(path) {
      const ref = firebase.storage().ref(path);
      ref.listAll()
        .then(dir => {
          dir.items.forEach(fileRef => {
            deleteFile(ref.fullPath, fileRef.name);
          });
          dir.prefixes.forEach(folderRef => {
            deleteFolderContents(folderRef.fullPath);
          });
          console.log("Folder deleted");
        })
        .catch(error => {
          console.log(error);
        });

    }


    /**
     * 
     */
    function deleteFile(pathToFile, fileName) {
      const ref = firebase.storage().ref(pathToFile);
      const childRef = ref.child(fileName);
      childRef.delete();
    }


    /**
     * 
     */
    function confirmDeleteExhibit(e, id) {

      // Debug
      // console.log("Deleting ", id);

                          
      firebase.firestore().collection('Exhibitions').doc(id).delete().then((e) => {

          // Debug
          // console.log("Document successfully deleted!", e);

          Utils.NotifyUserSuccess('Notificación','La exposición ha sido eliminada correctamente.');

          setSelectedExhibit(null);

          deleteFolderContents(id);

      }).catch((error) => {
          Utils.NotifyUserError('Notificación','La exposición no ha posido ser eliminada.');
          console.error("Error removing document: ", error);
      });

    }
    

    /**
     * 
     */
    function cancelDeleteExhibit(e) {
      // Debug
      // console.log("Delete exhibit has been cancelled");
    }


    /**
     * 
     * @param {*} e 
     * @param {*} pexhibit 
     */
    function editExhibit(e, pexhibit){
      // Debug
      // console.log("editing ....")

      setEditMode(true);
      setSelectedExhibit(pexhibit);
    }


    /**
     * 
     */
    const uploadImage = async (id, fileimage) => {

      const storage = firebase.storage();
      const storageRef = storage.ref();
      const fileRef = storageRef.child(id).child(fileimage.name.replace( /\s/g, ''));
      await fileRef.put(fileimage);
      const tmp = await fileRef.getDownloadURL();

      // console.log(tmp);

      return tmp;
      
    };


    /**
     * 
     */
    const deleteImageByURL = async (imageURL) => {
      const storage = firebase.storage();
      const storageRef = storage.ref();
      const fileRef = storageRef.refFromURL(imageURL);
      if(fileRef)
        return null

      return fileRef.delete();
    }


    /**
     * Confirme Update a given Exhibit
     */
    async function confirmUpdateExhibit(id, aexhibit, imageFiles){

      //! DeleteMe
      // console.log("Updating Exhibit");
      // console.log(id);
      // console.log(aexhibit);
      // console.log(images);
      // console.log("confirmUpdateExhibit", imageFiles);

      if(!id)
      {
        Utils.NotifyUserError("Error Actualizando", "Ha habido un error con el ID de la exposición seleccionada.")
        return;
      }

      if(!aexhibit)
      {
        Utils.NotifyUserError("Error Actualizando", "Ha habido un error con la exposición seleccionada.")
        return;
      }

      // Step 1: Process the Cuadros 

      // Process the files inputs
      let tmpCuadros     = [...aexhibit.cuadros];
      let updatedCuadros = [];
      
      for (let index = 0; index < tmpCuadros.length; index++) {

        if(!tmpCuadros[index]?.imagenData)
        {
          delete tmpCuadros[index].imagenData;
          updatedCuadros.push(await tmpCuadros[index]);
          continue;
        }

        const imgURL = await uploadImage(id, tmpCuadros[index].imagenData);
        tmpCuadros[index].imagen = imgURL;
        delete tmpCuadros[index].imagenData;
        updatedCuadros.push(await tmpCuadros[index]);
      };


      let finalExhibit = {...aexhibit, cuadros: updatedCuadros};

      // Step 2: 
      var pexhibit = firebase.firestore().collection("Exhibitions").doc(id);

      pexhibit.update({...finalExhibit})
        .then(() => {

            Utils.NotifyUserSuccess('Notificación','La exposición ha sido actualizada correctamente.');

            // Debug
            // console.log("Document successfully updated!")

            // Upload the imagen to the FireStore 
            if(imageFiles){
              uploadImage(id, imageFiles)
              .then( imgURL => {
                // Debug
                // console.log(imgURL)
                var pexhibit = firebase.firestore().collection("Exhibitions").doc(id);
                pexhibit.update({poster: imgURL});
                // selectExhibitByID(id);
              } )
            }

            selectExhibitByID(id);
            setEditMode(false);
            
        })
        .catch((error) => {
            Utils.NotifyUserError('Notificación','La exposición no ha podido ser actualizada.');
            console.error("Error updating document: ", error);
        });

    }


    function cancelUpdateExhibit(e, pexhibit){
      setEditMode(false);
      setSelectedExhibit(selectedExhibit);
    }

  /*
  const tempExhibits = [
    {
        title: 'Exhibit 1',
        active: true,
        key: '1',
        dateCreated: "2021-07-28",
        dateStart: "2021-07-28",
        dateEnd: "2021-07-31",
        description_es: "Hola 1 Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras bibendum et mauris sed eleifend. Vestibulum feugiat ac erat rutrum suscipit.",
        description_en: "Hello 1 Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras bibendum et mauris sed eleifend. Vestibulum feugiat ac erat rutrum suscipit.",
        poster: "https://virtual.lanzaroteartgallery.com/assets/cuadros/poster.png"
    }
  ];
  */


  const [exhbitions, setExhibitions] = useState([]);
  const [loading, setLoading]        = useState(false)

  const pExhibitions =  firebase.firestore()
                                .collection('Exhibitions')
                                .orderBy("dateCreated", "desc");

  // Upload the Exhibitions 
  function getExibitions(){

      setLoading(true);

      // console.log("getExibitions :: PREV :: ", selectedExhibit.id);
      // let selectID = null;
      // if(selectedExhibit)
      //   selectID = selectedExhibit.id;

      pExhibitions.onSnapshot((query) => {
          const items = [];
          query.forEach( (doc) => {
              items.push( { id: doc.id, ...doc.data() } );

              // DEBUG: 
              // console.log({id: doc.id, ... doc.data()});

          } );

          setExhibitions(items);
          setLoading(false);
          console.log("getExibitions() :: Selected Exhibit :: ", selectedExhibit)
          if(selectedExhibit)
            setSelectedExhibit(selectedExhibit);
          
      });
  }

  // Populate the Exhibitions 
  useEffect(()=> {
      getExibitions();
  }, []);


  //
  const [exhibitSettings, setExhibitSettings] = useState({});
  
  //
  const pSettings = firebase.firestore()
                            .collection('Settings');

  // Get the Setting from the server
  function getSettings(){

      pSettings.doc("global").onSnapshot((query) => {
         
          // Debug 
          // console.log("Setting Loading:")
          
          setExhibitSettings(query.data());

          // Debug
          // console.log(exhibitSettings);
          
      });
  }

  //
  useEffect(()=> {
    getSettings();
  }, []);

  //
  // const [userLogin, setUserLogin] = useState(false);

  // Get access to Logut callback and Current User
  const { logout, currentUser } = useAuth();
  const [error, setError]       = useState('');
  const history                 = useHistory();

  //
  async function handleLogout() {
    setError("")

    try {
      await logout()
      history.push("/login")
    } catch {
      setError("Failed to log out")
    }
  }


  // Return the Component
  return (

        <div className={classes.root}>
          <CssBaseline />

          {/*------------------------------------------------------------------- */}
          {/*               M A I N   L E F T    D R A W E R                     */}
          {/*------------------------------------------------------------------- */}
          <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{ paper: classes.drawerPaper }}
          >

            <img src={logo} className={classes.AppLogo} alt="logo" style={{'padding':'10px'}} />
            <Divider />

            <div className={classes.drawerContainer}>

            {/******* Top Left Menu Items *******/}
            <List subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                    Panel de Gestión
                </ListSubheader> } 
            >
                <ListItem key="btnExhibits" >
                    <ListItemIcon style={{'minWidth':'30px'}}> <AccountBalanceIcon /> </ListItemIcon>
                    <ListItemText primary="Exposiciones" secondary={' (' + exhbitions.length + ')'} disableTypography className={classes.listTitle} />
                    <ListItemSecondaryAction>
                        <IconButton edge="end" aria-label="delete" onClick={e => setOpenModalNewExhibit(true)} > 
                            <AddPhotoAlternateOutlinedIcon/>
                        </IconButton>
                        <ModalNewExhibit open={openModalNewExhibit} onClose={handleCloseModalNewExhibit} handleClose={handleModalNewExhibitAnswer} createNewExhibit={createNewExhibit} />
                    </ListItemSecondaryAction>
                </ListItem>

                <List component="div" disablePadding dense className="list-exhibitions" >

                  {/* { exhibits.map( (curExhibit) =>  */}
                  { exhbitions.map( (curExhibit) => 
                        {
                        return <>
                            <ListItem button className={classes.nested} 
                                key={curExhibit.id} 
                                item={curExhibit}
                                onClick={ e => exhibitClick(e, curExhibit) }
                                className={ (curExhibit.id ===  selectedExhibit?.id ? 'exhibitListItemSelected':'' )}
                                >

                                <ListItemIcon style={{'minWidth':'35px', 'width':'20px'}}>  
                                    <PhotoLibraryOutlinedIcon color={curExhibit.active ? 'primary' : 'secondary'} style={ {'width':'20px' }}/>
                                </ListItemIcon>
                                <span style={{'width': '115px'}}>
                                  {curExhibit.title}
                                </span>
                                <ListItemSecondaryAction>
                                    <IconButton edge="end" aria-label="edit" onClick={e => editExhibit(e, curExhibit)}>
                                            <EditOutlinedIcon />
                                    </IconButton>
                                    <Popconfirm
                                        title="Estas seguro que quieres eliminar esta exposición?"
                                        onConfirm={e => confirmDeleteExhibit(e, curExhibit?.id)}
                                        onCancel={cancelDeleteExhibit}
                                        okText="Si"
                                        cancelText="No"
                                        placement="right" 

                                      >
                                        <IconButton edge="end" aria-label="delete">
                                            <DeleteIcon />
                                        </IconButton>
                                      </Popconfirm>
                                </ListItemSecondaryAction>
                            </ListItem>
                        </>
                        
                        }
                    )}

                </List>
            </List>

            {/******* Bottom Left Menu Items *******/}
            <List style={{'position':'absolute', 'bottom':'0px', 'width':'100%', 'height':'auto !important'}}
                subheader={<>
                <Divider />
                <ListSubheader component="div" id="nested-list-subheader">
                    Panel de Configuración
                </ListSubheader> </>}  
            dense
            >
            
                <ListItem button key="btnSettings"  onClick={e => setModalSettings(true)} >
                    <ListItemIcon> <SettingsOutlinedIcon /> </ListItemIcon>
                    <ListItemText primary="Configuración" />
                </ListItem>
                <ModalSettings open={openModalSettings} handleClose={e => setModalSettings(false)} />

                <ListItem button key="btnAbout" onClick={e => setModalAbout(true)}>
                    <ListItemIcon> <InfoOutlinedIcon /> </ListItemIcon>
                    <ListItemText primary="Acerca de" />
                </ListItem>
                <ModalAbout open={openModalAbout} handleClose={e => setModalAbout(false)} />

                 <Popconfirm
                    title="¿Estas seguro que quieres salir de la aplicación?"
                    onConfirm={handleLogout}
                    okText="Si"
                    cancelText="No"
                    placement="rightBottom" 

                  >
                    <ListItem button key="btnLogout" >
                      <ListItemIcon> <ExitToAppIcon /> </ListItemIcon>
                    <ListItemText primary="Salir" />
                </ListItem>
                </Popconfirm>
                

            </List>

            </div>
          </Drawer>

          {/*------------------------------------------------------------------- */}
          {/*                     M A I N    C O N T E X T                       */}
          {/*------------------------------------------------------------------- */}
          <main className={classes.content}>

            <div style={{'width':'100%'}} > 

            {/* 3D Viewport Widget */}
            <Paper elevation={3} style={{'minHeight':'350px', 'marginBottom':'0px', 'borderTopLeftRadius':'5px', 'borderTopRightRadius':'5px'}}>
                <div  key="1" style={{'minHeight':'350px', 'width':'100%', 'borderTopLeftRadius':'5px', 'borderTopRightRadius':'5px'}}>
                  <ThreeJSComponent exhibit = {selectedExhibit}  settings={exhibitSettings} />
                </div>
            </Paper>

            <Card className={classes.card} style={{'minHeight':'45vh'}}>
                <TabExhibit exhibit={selectedExhibit} 
                            onPerformUpdate={confirmUpdateExhibit} 
                            onCancelUpdate={e => cancelUpdateExhibit(e, selectedExhibit) }
                            editMode={editMode} 
                            refresh={refresh} />
            </Card>

            </div>

          </main>
        
          {/* < Copyright/> */}
      
        </div>

  );
}
