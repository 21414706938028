import React from 'react';
import ReactDOM from 'react-dom';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import {
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns'
import esLocale from "date-fns/locale/es";

import 'antd/dist/antd.css';

import './index.css';
import App from './App';
import AppMain from './AppMain';

// Or Create your Own theme:
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#778899'
    },
    secondary: {
      main: 'rgb(200,200,200)'
    }
  }
});

// Main Render Component
ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
        <AppMain />
    </MuiPickersUtilsProvider>
  </MuiThemeProvider>,
  document.getElementById('root')
);
