
import {React, useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {Grid, FormControlLabel, Switch } from '@material-ui/core/';
import { KeyboardDatePicker } from '@material-ui/pickers';
import BackupIcon from '@material-ui/icons/Backup';
import UploadImage from './UploadImage';
import * as Utils from './Utils.js'


function ModalNewExhibit(props) {

    const [title, setTitle]                 = useState('');
    const [titleError, setTitleError]       = useState();
    const [titleErrorMsg, setTitleErrorMsg] = useState();
    const [dateStart, setDateStart]         = useState(new Date());
    const [dateEnd, setDateEnd]             = useState( (new Date()).setMonth(dateStart.getMonth() + 1 ));
    const [isActived, setIsActived]         = useState(false);
    const [descriptionES, setdescriptionES] = useState('');
    const [descriptionESError, setDescriptionESError] = useState();
    const [descriptionESErrorMsg, setDescriptionESErrorMsg] = useState();
    const [descriptionEN, setDescriptionEN] = useState('');
    const [poster, setPoster]               = useState('');
    const [posterData, setPosterData]       = useState();
    const [isvalid, setIsValid]             = useState(false);



    function dataIsValid(e){

        setTitleError(null);
        setTitleErrorMsg(null);
        setDescriptionESError(null);
        setDescriptionESErrorMsg(null);


        // Sanity Check #1: Title 
        if(!title.trim()){  
            setTitleError('Error Grande');
            setTitleErrorMsg('El titulo es obligatorio');
            // console.log("Title Empty", title);
            return false;
        }

        // Sanity Check #2: Description 
        if(!descriptionES.trim()){  
            setDescriptionESError('Error Pequeño');
            setDescriptionESErrorMsg('Se debe proporcionar una descripción');
            // console.log("Description Empty", descriptionES);
            return false;
        }

        return true;

    }


    function resetData(){
        setTitle('');
        setTitleError();
        setTitleErrorMsg();
        setDateStart(new Date());
        setDateEnd( (new Date()).setMonth(dateStart.getMonth() + 1 ));
        setIsActived(false);
        setdescriptionES('');
        setDescriptionESError();
        setDescriptionESErrorMsg();
        setDescriptionEN('');
        setPoster('');
        setPosterData();
        setIsValid(false);
    }


    function readURI(e){
        if(e.target.files && e.target.files[0]){
            let reader = new FileReader();
            reader.onload = function(ev){
                setPoster(ev.target.result); 
            }.bind(this);
            reader.readAsDataURL(e.target.files[0]);
        }
    }
    

    function handleSelectedFileChange(e){

        // Sanity Check: Selected file is not empty
        if(e.target.files.length === 0)
        {
            console.error("Array empty")
            return;
        }

        // Get selected file
        let tempFile = e.target.files[0];
       
        // Sanity Check: File is bellow some limit
        if(tempFile.size/1024/1024 > 1.5) 
        {  
            alert('El tamaño del archivo es ' + (tempFile.size/1024/1024).toFixed(2) + " MB y deberia ser menos de 1.5 MB");
            e.target.value = '';
            return;
        }
    
        setPosterData(e.target.files[0]); 
        readURI(e);
    }


    function handleSubmit(e){

        e.preventDefault();

        // Sanity Check: Valid data
        if(!dataIsValid()){
            return;
        }

        // Create a new Exhibit 
        props.createNewExhibit({
            title: title,
            active: isActived,
            poster: poster,
            posterData: posterData,
            dateCreated: new Date(),
            dateStart: dateStart,
            dateEnd: new Date(dateEnd),
            description_es: descriptionES,
            description_en: descriptionEN,
            cuadros: []
        });

        resetData();

        // Close the Form
        props.onClose();

    }


    return (
        <div>
            <Dialog open={props.open} 
                    onClose={props.handleClose} 
                    aria-labelledby="form-dialog-title"
                    fullWidth 
                    maxWidth="md">
                <DialogTitle id="form-dialog-title">Nueva Exposición</DialogTitle>
                <DialogContent disabled>
                {/* <DialogContentText>
                    Introduce Text erwerwer wrewerwer werwerwer werwe wer
                </DialogContentText> */}
               

                <Grid container spacing={3} style={{'padding':'1px'}} alignItems="stretch" >
                    <Grid item xs={3} style={{'textAlign':'center', 'marginTop':'40px'}}>
                        {              
                                poster?
                                    <img src={ poster || ''}  
                                        alt="No Poster" 
                                        className="upload-image"/>
                                :
                                    <div className="upload-image"> Sin Poster </div>
                            }
                            <input type='file' 
                                onChange={e => handleSelectedFileChange(e) }
                                id="selectedFileNewExhibit"
                                hidden
                                disableUnderline >
                            </input>
                            <Button variant='contained' 
                                    style={{'width':'100%', 'marginTop':'10px', 'height': '39.5px'}} 
                                    onClick={ e => { 
                                        document.getElementById('selectedFileNewExhibit')?.click(); } }
                                    >
                                    <BackupIcon style={{'paddingRight':'5px'}} />  
                                    Subir Poster 
                            </Button>
                    </Grid>
                    <Grid item xs={9}>
                        <Grid container spacing={3} style={{'padding':'1px'}}>
                            <Grid item xs={12} style={{'display':'inherit'}}>
                                <TextField
                                    required
                                    autoFocus
                                    id="id_title"
                                    label="Titulo"
                                    size="small"
                                    fullWidth
                                    margin='dense'
                                    autoComplete="none"
                                    value = {title}     
                                    error={titleError}  
                                    helperText={titleErrorMsg}                           
                                    onChange = {e => setTitle(e.target.value) }
                                />
                                <FormControlLabel
                                    value="top"
                                    control={<Switch id="id_active" color="primary" onChange={event => setIsActived(event.target.checked)} />  }
                                    label="Activo"
                                    id="id_active_parent"
                                    labelPlacement="top"
                                />
                            </Grid>
                        </Grid>
                        
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <KeyboardDatePicker
                                    autoOk
                                    dense="true"
                                    disablePast="true"
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    id="id_dateStart"
                                    label="Comienza"
                                    value={dateStart}
                                    onChange={ ndate => { setDateStart(ndate) }}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    style={{'marginBottom':'10px'}}
                                /> 
                            </Grid>
                            <Grid item xs={6}>
                                <KeyboardDatePicker
                                        autoOk
                                        dense="true"
                                        disablePast="true"
                                        variant="inline"
                                        format="dd/MM/yyyy"
                                        id="id_dateEnd"
                                        label="Termina"
                                        value={dateEnd}
                                        onChange={ndate => { setDateEnd(ndate) }}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        style={{'marginBottom':'10px'}}
                                    />                    
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={6} spacing={9}>
                                <TextField
                                    required
                                    dense="true"
                                    id="id_description_es"
                                    label="Descripción (ES)"
                                    size="small"
                                    autoComplete="none"
                                    fullWidth
                                    multiline
                                    rows={9}                            
                                    error={descriptionESError}  
                                    helperText={descriptionESErrorMsg}                           
                                    onChange = {e => setdescriptionES(e.target.value) }
                                />         
                            </Grid>
                            <Grid item xs={6} spasing={9}>
                                <TextField
                                    dense="true"
                                    id="id_description_en"
                                    label="Descripción (EN)"
                                    size="small"
                                    autoComplete="none"
                                    fullWidth
                                    multiline
                                    rows={9}
                                    onChange = {e => setDescriptionEN(e.target.value) }
                                />
                            </Grid>
                        </Grid>   
                    </Grid>
                </Grid>

            </DialogContent>

            <DialogActions>
                <Button onClick={props.onClose} color="primary">
                    Cancelar
                </Button>
                <Button onClick={handleSubmit} color="primary" variant="contained"> 
                    Crear
                </Button>
            </DialogActions>
        </Dialog>
    </div>
    )
}



export default ModalNewExhibit
