import React, {useState, useRef} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';

import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import { useAuth } from './Contexts/AuthContext'
import { Alert } from 'antd';

import { useHistory } from "react-router-dom";
import logo from './logo.png';


const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: 'url(https://lanzaroteartgallery.com/wp-content/uploads/2020/12/IMG_1750.jpg)', 
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  AppLogo:{
    width: '45vh',
    filter: 'brightness(0) invert(1)',
  },
}));


/**
 * 
 * @returns 
 */
function Copyright() {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="https://lanzaroteartgallery.com/">
          Lanzarote Art Gallery
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
}


/**
 * 
 * @returns 
 */
export default function ForgotPassword() {

    const classes     = useStyles();
    const emailRef    = useRef();
    const { resetPassword }     = useAuth();
    const [error, setError]     = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const history = useHistory();


    async function handleSubmit(e){ 

        // Prevent to refresh after submit the form
        e.preventDefault();
    
        try{
            setMessage('');
            setError('');
            setLoading(true); 
            await resetPassword(emailRef.current.value);
            setMessage('Revise la bandeja de entrada de su correo electrónico, ' + emailRef.current.value + ', para obtener más instrucciones');
            setLoading(false);
            emailRef.current.value = '';
        }catch {
            setError('Ha habido un error en la recuperación de contraseña. Por favor, confirme que el email proporcionado es el correcto.');
            setLoading(false); 
        }
    }

    return (
        <Grid container component="main" className={classes.root}>
            <CssBaseline />
            <Grid item xs={false} sm={4} md={7} className={classes.image}>
                <img src={logo} className={classes.AppLogo} alt="logo" style={{'padding':'10px'}} />
            </Grid>

            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                <div className={classes.paper}>
                
                {/* HEADER  */}
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Recuperar Contraseña
                </Typography>

                {error   && <Alert message="Error Recuperación de Contraseña" description={error} type="error" showIcon style={{'marginTop' : '20px' , 'marginBottom' : '20px' }} />}
                {message && <Alert message="Recuperación de Contraseña Satisfactoria" description={message} type="success" showIcon style={{'marginTop' : '20px', 'marginBottom' : '20px'  }}/>}
               
                {/*  BODY CONTENT */}
                <form className={classes.form}  onSubmit={handleSubmit}>
                    <TextField
                        variant="outlined"
                        margin="normal" 
                        required
                        fullWidth
                        id="email"
                        label="Dirección de correo electrónico"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        inputRef={emailRef}
                    />
                    
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        disabled={loading}
                    >
                        Resetear Contraseña
                    </Button>

                    <Grid container>
                        <Grid item xs>
                            <Link href="/login" variant="body2">
                                ir a Iniciar Sesión
                            </Link>
                        </Grid>
                    </Grid>

                    <Box mt={5}>
                        <Copyright />
                    </Box>

                </form>
                </div>
            </Grid>
        </Grid>
    );
}