
import React, {useState, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import * as Utils from './Utils'
import firebase from './firebase';

function ModalSettings(props) {


    const [avgHeightCuadro, setavgHeightCuadro] = useState(160);
    const [avgHeightCamera, setavgHeightCamera] = useState(160);

    useEffect(() => {

        var docRef = firebase.firestore().collection("Settings").doc("global");

        docRef.get().then((doc) => {
            if (doc.exists) {
                // console.log("Document data:", doc.data());
                const settings = doc.data();
                setavgHeightCuadro(settings.canvasHeight);
                setavgHeightCamera(settings.cameraHeight);
            } else {
                // doc.data() will be undefined in this case
                Utils.NotifyUserError("Error Lectura","Los datos de configuración no existe en el servidor.");
                docRef.set({
                    canvasHeight: avgHeightCuadro,
                    cameraHeight: avgHeightCamera
                });
            }
        }).catch((error) => {
            Utils.NotifyUserError("Error Lectura","No se ha podido cargar los datos desde el servidor.");
            console.error("Error getting document:", error);
        });

    }, [])


    function onChange(e){

        // Allow only numbers
        const tmp = e.target.value.replace(/\D/g,'');

        // Update the states
        switch(e.target.id){
            case 'id_height_cuadro':
                setavgHeightCuadro(tmp);
            break;
            case 'id_height_camera':
                setavgHeightCamera(tmp);
            break;
            default:
                break;
        }
        
     }

    function handleSubmit(){

        // console.log("Guardando Settings")

        var docRef = firebase.firestore().collection("Settings").doc("global");

        docRef.update({
            canvasHeight: avgHeightCuadro,
            cameraHeight: avgHeightCamera
        })
        .then(() => {
            Utils.NotifyUserSuccess("Notificación","La nueva configuración se ha guardado con éxito.");
            props.handleClose();
        })
        .catch((error) => {
            // The document probably doesn't exist.
            console.error("Error updating document: ", error);
            Utils.NotifyUserError("Error Actualizando","La nueva configuración se ha podido guardado.");
        });

    }

    return (
        <div>
            <Dialog open={props.open} onClose={props.handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Configuración</DialogTitle>
                <DialogContent>
                <DialogContentText>
                    En este panel podrá configurar los parametros globales de la herramienta.
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="id_height_cuadro"
                    label="Altura Media Obra [cm]"
                    name="height_cuadro" 
                    fullWidth
                    defaultValue={'160'}
                    value = { avgHeightCuadro}
                    onChange= {e => onChange(e)}
                />
                <TextField
                    margin="dense"
                    id="id_height_camera"
                    name="height_camera"
                    label="Altura Media Camera [cm]"
                    fullWidth
                    defaultValue={'160'}
                    value = { avgHeightCamera}
                    onChange= {e => onChange(e)}
                />
                </DialogContent>
                <DialogActions>
                <Button onClick={props.handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleSubmit} color="primary" variant="contained">
                    Guardar
                </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default ModalSettings
