import React from 'react';
// import ReactDOM from 'react-dom';
import 'antd/dist/antd.css';
import './index.css';
import { Upload, message , Modal, Button, Space} from 'antd';
import { LoadingOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';

/**
 * Transform Image data
 * @param {*} img 
 * @param {*} callback 
 */
function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

/**
 * Validate Image
 * @param {*} file 
 * @returns 
 */
function beforeUpload(file) {

  // Sanity Check: Only JPG or PNG Images
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }

  // Sanity Check: Limit the Size of the image
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;

}



class UploadImage extends React.Component {

  state = {  
    loading: false,
    previewVisible: false,
    previewImage: '',
    previewTitle: ''
  };


  constructor(props){
    super(props)
    this.state = { fileList: [
      {
        uid: '-1',
        name: 'image.png',
        status: 'done',
        url: props.currImage,
      }],
      active: props.disabled
    };
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.currImage !== prevProps.currImage) {
      this.setState({ fileList: [
        {
          uid: '-1',
          name: 'image.png',
          status: 'done',
          url: this.props.currImage,
        }]
      });
    }

    if (this.props.disabled !== prevProps.disabled)
      this.setState({ active: this.props.disable}); 
  }
  


  handleChange = info => {

    if (info.file.status === 'uploading') {
      this.setState({ loading: true });
      return;
    }

    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, imageUrl =>
        this.setState({ imageUrl, loading: false }),
      );
    }
  };

  /**
   * 
   * @returns 
   */
  handleCancel = () => this.setState({ previewVisible: false });


  /**
   * 
   * @param {*} param0 
   * @returns 
   */
  handleChangeFiles = ({ fileList }) => this.setState({ fileList });


  /**
   * 
   * @param {*} file 
   */
  handlePreview = async file => {

    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    });
    
  };


  /**
   * 
   * @returns 
   */
  render() {
    const { loading,
            imageUrl, 
            previewVisible, 
            previewImage, 
            fileList, 
            previewTitle,
            active } = this.state;

    const uploadButton = (
      <div>
        {/* {loading ? <LoadingOutlined /> : <UploadOutlined />}*/}
        <Button icon={<UploadOutlined />}>Click to Upload</Button>
      </div>
    );

    return (
      <>
        <Upload
          // name="avatar"
          listType="picture-card"
          className="image-uploader"
          // showUploadList={false}
          fileList={fileList}
          action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
          beforeUpload={beforeUpload}
          onChange={this.handleChangeFiles}
          onPreview={this.handlePreview}
          maxCount={1}
          disabled = {active}
        >
            {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }}  width='200px' /> : uploadButton}
        </Upload>
        <Modal
          visible={previewVisible}
          title={previewTitle}
          footer={null}
          onCancel={this.handleCancel}
          style={{ width: '100%' }}
        >
          <img alt="example" style={{ width: '100%' }} src={previewImage} />
        </Modal>
      </>
    );
  }
}

export default UploadImage;