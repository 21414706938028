import React, { Component, useStyles} from 'react'

import AppBar from '@material-ui/core/AppBar';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { 
    // Box,
        ListSubheader, 
        Paper, 
        TextField,
        Switch,
        Grid,
        Toolbar,
        List,
        ListItem,
        ListItemIcon,
        ListItemText,
        InputAdornment,
        Select,
        MenuItem,
        InputLabel,
        Slider,
        CardActions,
        Button,
        Typography,
        IconButton,
        ListItemSecondaryAction,
        Box,
        Chip,
        Tooltip
    } from '@material-ui/core';

import {HeightTwoTone as HeightIcon, EuroSharp as EuroIcon} from '@material-ui/icons/';
import BackupIcon from '@material-ui/icons/Backup';
import DeleteIcon from '@material-ui/icons/Delete';
import AddPhotoAlternateOutlinedIcon from '@material-ui/icons/AddPhotoAlternateOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import LanguageIcon  from '@material-ui/icons/Language';
import { Face } from '@material-ui/icons/';

// Import from AntDesign UI
import { Table, Popconfirm } from 'antd';

// Unique ID Generator
import uuidv4 from 'uuid/v4';

// Project Components
import TabPanel from './TabPanel';
import UploadImage from './UploadImage';
import AppEnums from './AppEnums'
import firebase from './firebase';
import * as Utils from './Utils';
import './App.css';
import { AccountBalance } from '@material-ui/icons';
import reactDom from 'react-dom';


/**
 * 
 */
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};



/**
 * Columns Structure for the Canvas
 */
const exhibitStructureColumns = [
    {
        title: 'Imagen',
        dataIndex: 'imagen',
        key: 'imagen',
    },
    {
        title: 'Autor',
        dataIndex: 'autor',
        key: 'autor',
    },
    {
        title: 'Titulo',
        dataIndex: 'titulo',
        key: 'titulo',
    },
    {
        title: 'Descripción',
        dataIndex: 'descripcion',
        key: 'descripcion',
    },
    {
        title: 'Alto',
        dataIndex: 'height',
        key: 'height',
    },
    {
        title: 'Ancho',
        dataIndex: 'width',
        key: 'width',
    },
    {
        title: 'Técnica',
        dataIndex: 'canvas',
        key: 'canvas',
    },
    {
        title: 'Material',
        dataIndex: 'material_es',
        key: 'material_es',
    },
    {
        title: 'Precio',
        dataIndex: 'precio',
        key: 'precio',
    },
    {
        title: 'Pared',
        dataIndex: 'wallid',
        key: 'wallid',
    },
    {
        title: 'Offset',
        dataIndex: 'offset',
        key: 'offset',
    },
    {
        title: 'Escala',
        dataIndex: 'scalefactor',
        key: 'scalefactor',
    },
    {
        title: 'Url',
        dataIndex: 'url',
        key: 'url',
    }
];


/**
 * 
 * @param {*} index 
 * @returns 
 */
function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
};


function getPathStorageFromUrl(url){

    // ! TODO Change this for each project. Move it to an enviorement variable
    const baseUrl = "https://firebasestorage.googleapis.com/v0/b/virtualgallery-lanzarote.appspot.com/o/";

    let imagePath = url.replace(baseUrl,"");

    const indexOfEndPath = imagePath.indexOf("?");

    imagePath = imagePath.substring(0,indexOfEndPath);
    
    imagePath = imagePath.replace("%2F","/");
    
    return imagePath;
}




export class TabExhibit extends Component {

    constructor(props){
        super(props);
        //const { exhibit, value, index, ...other } = props;
        const { exhibit, ...other } = props;

        this.state = {
            curExhibit: this.props.exhibit,
            tabPage: 0, // TODO change back to 0
            dateCreated: new Date(),
            dateStart: new Date(),
            dateEnd: new Date( ).setDate( (new Date()).getMonth() + 1),
            activedExhibit: false,
            curImage: this.props.exhibit?.poster,
            curImageFile: null,
            scaleFactor: 1,
            offsetFactor: 0,
            editMode: this.props.editMode,
            cuadros: ( this.props.exhibit?.cuadros ||  [] ),
            curCuadro: null
        };

        // this.setState({curExhibit: this.props.exhibit});
        // this.setState({cuadros: ( this.props.exhibit?.cuadros ||  [] )})
        this.myChangeHandler           = this.myChangeHandler.bind(this);
        this.isEditModeON              = this.isEditModeON.bind(this);
        this.handleDateStartChange     = this.handleDateStartChange.bind(this);
        this.handleDateEndChange       = this.handleDateEndChange.bind(this);
        this.handleTabChange           = this.handleTabChange.bind(this);
        this.handleTabChangeIndex      = this.handleTabChangeIndex.bind(this);
        this.sendUpdate                = this.sendUpdate.bind(this);
        this.handleInputChange         = this.handleInputChange.bind(this);
        this.handleSelectCuadro        = this.handleSelectCuadro.bind(this);
        this.handleCuadroImageChange   = this.handleCuadroImageChange.bind(this);
        this.saveChangesSelectedCuadro = this.saveChangesSelectedCuadro.bind(this);
        this.scrollToBottom            = this.scrollToBottom.bind(this);
        
    }

    componentWillReceiveProps(){
        this.forceUpdate();
        this.setState({editMode: this.props.editMode});
        // this.setState({curExhibit: this.props.exhibit});
        // this.setState({activedExhibit: this.props.exhibit?.active});
        // this.setState({dateStart: (this.props.exhibit?.dateStart?.toDate() || new Date() ) });
        // this.setState({dateEnd:   (this.props.exhibit?.dateEnd?.toDate() || new Date() )});
        // console.log("TabExhibit :: Receive :: EditMode :: ", this.state.editMode );
    }

    componentDidMount() {
       this.setState({curExhibit: this.props.exhibit})
       this.setState({curImage: this.props.exhibit?.poster})
       this.setState({cuadros: ( this.props.exhibit?.cuadros ||  [] )})
       // debug
       // console.log(this.state.cuadros);
    }

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.exhibit !== prevProps.exhibit) {
            this.setState({curExhibit: this.props.exhibit})
            this.setState({activedExhibit: this.props.exhibit?.active});
            this.setState({dateStart: (this.props.exhibit?.dateStart?.toDate() || new Date() ) });
            this.setState({dateEnd:   (this.props.exhibit?.dateEnd?.toDate() || new Date() )});
            this.setState({curImage: this.props.exhibit?.poster})
            this.setState({cuadros: ( this.props.exhibit?.cuadros ||  [] )})
            // Debug
            // console.log("TabExhibit :: Update ::", this.state.curExhibit );
            // console.log(this.state.cuadros);
        }
        if (this.props.editMode !== prevProps.editMode){
            this.setState({editMode: this.props.editMode});
            // debug
            // console.log("TabExhibit :: Update :: EditMode :: ", this.state.editMode );
        }
    }

    handleTabChange(event, newValue){
        this.setState({ tabPage: newValue})
    };

    handleTabChangeIndex(index){
        this.setState({ tabPage: index})
    };



    //----------------------------------------------------------------------//
    //    M e t h o d s    f o r    t h e    E x h i b i t                  //
    //----------------------------------------------------------------------//


    handleDateStartChange(date){
        this.setState({dateStart: date});
    };

    handleDateEndChange(date){
        this.setState({dateEnd: date})
    };

    myChangeHandler(event){

        let idElement = event?.target?.id;
        let val = event?.target?.value;

        // console.log(event)

        // 1. Make a shallow copy of the item you want to mutate
        let tmpExhibit = {...this.state.curExhibit};
    
        // 2. Replace the property you're intested in
        switch(idElement){
            case 'id_title':
                tmpExhibit.title = val;
                break;
            case 'id_active':
                this.setState({activedExhibit: event.target.checked});
                tmpExhibit.active = event.target.checked;
                break;
            // case 'id_dateStart':
            //     tmpExhibit.dateStart = val;
            //     break;
            // case 'id_dateEnd':
            //     tmpExhibit.dateEnd = val;
            //     break;
            case 'id_description_es':
                tmpExhibit.description_es = val;
                break;
            case 'id_description_en':
                tmpExhibit.description_en = val;
                break;
            default: break;
        }

        // 3. Update the State with the new object
        // this.setState( { curExhibit: tmpExhibit}, 
        //     () => this.props.updateExhibit(this.state.curExhibit) );
        this.setState( { curExhibit: tmpExhibit});

    }

    readURI(e){
        if(e.target.files && e.target.files[0]){
            let reader = new FileReader();
            reader.onload = function(ev){
                this.setState({curImage: ev.target.result});
            }.bind(this);
            reader.readAsDataURL(e.target.files[0]);
        }
    }
    

    handleInputChange(e){

        // Sanity Check: Selected file is not empty
        if(e.target.files.length === 0)
        {
            console.error("Array empty")
            return;
        }

        // Get selected file
        let tempFile = e.target.files[0];
       
        // Sanity Check: File is bellow some limit
        if(tempFile.size/1024/1024 > 1.5) 
        {  
            alert('El tamaño del archivo es ' + (tempFile.size/1024/1024).toFixed(2) + " MB y deberia ser menos de 1.5 MB");
            e.target.value = '';
            return;
        }

        this.setState({curImageFile: e.target.files[0]}); 
        this.readURI(e);
    }


    isEditModeON(){
        return false; // (!this.state.editMode ? false : false);
    }


    sendUpdate(){
        
        // 1. Make a shallow copy of the item you want to mutate
        let tmpExhibit = {...this.state.curExhibit};
    
        // 
        this.props.onPerformUpdate(this.state.curExhibit.id, 
            {
            title: this.state.curExhibit.title,
            active: this.state.curExhibit.active,
            poster: this.state.curExhibit.poster,
            dateStart: this.state.dateStart,
            dateEnd: this.state.dateEnd,
            description_es: this.state.curExhibit.description_es,
            description_en: this.state.curExhibit.description_en,
            cuadros: this.state.cuadros
        },
        this.state.curImageFile
        );

        // this.forceUpdate();
    };


    //----------------------------------------------------------------------//
    //    M e t h o d s    f o r    t h e    l i s t   o f   C a n v a s    //
    //----------------------------------------------------------------------//


    handleSelectCuadro(id){

        // Sanity Check
        // console.log("selecting:", id);

        // Sanity Check: Valid provided id
        if(Utils.isNil(id) || Utils.isEmpty(id))
        {
            this.setState({curCuadro: null});
            return;
        }

        // Sanity Check: Avoid selecint the same cuadro
        if(this.state.curCuadro?.id === id)
            return;

        // Make a copy of the current selected cuadro
        let prevSelectedCuadro = {...this.state.curCuadro};

        // find in the array the new givem cuadro
        let newSelection = this.state.cuadros.find( ( acuadro ) => acuadro.id === id );

        // Set the new selected cuadro
        this.setState({ curCuadro: newSelection });

        // Sanity Check: 
        if(prevSelectedCuadro === null)
            return;

        // Copy the list of cuadros
        let tmpCuadros = [...this.state.cuadros];

        // Find the index of the previous selected cuadro
        let index = tmpCuadros.findIndex( ( acuadro => acuadro.id === prevSelectedCuadro.id ) );

        // Override the cuadro in the list with the previous section (to grab the changes)
        tmpCuadros[index] =  {...prevSelectedCuadro};

        // Save the new updated list 
        this.setState({cuadros: tmpCuadros });

    }


    createCuadro(){
        return {
            id: uuidv4(),
            author: '',
            title: '',
            description_es: '',
            description_en: '',
            height: 100,
            width: 100,
            material: 0,
            technique: 0,
            imagen: '',
            imagenData: null, 
            price: 0,
            sold: false,
            url: '',
            wallID: 0,
            offset: 0,
            scalefactor: 1
        }
    }


    addCuadro(){
        let tempCuadros = [...this.state.cuadros];
        tempCuadros.push(this.createCuadro());
        this.setState({cuadros: tempCuadros}, () =>  this.scrollToBottom() );

        // debug
        // console.log(this.state.cuadros);
    }


    deleteCuadro(id){
        
        // Sanity Check
        if(Utils.isNil(id) || Utils.isEmpty(id))
            return; 

        // Make a temporal copy
        let tempCuadros = [...this.state.cuadros];

        // Find the index of the current selected cuadro
        let index = tempCuadros.findIndex( ( acuadro => acuadro.id === id ) );

        if(tempCuadros[index].imagen !== '' && tempCuadros[index].imagen.includes("data:") === false){

            let pathFile = getPathStorageFromUrl( tempCuadros[index].imagen );

            // Debug
            // console.log("Exhibit: ", this.state.curExhibit.id);
            // console.log("Deleting Cuadro: ", tempCuadros[index])
            // console.log("File: ", pathFile)

            var storagerf = firebase.storage().ref()
            var desertRef = storagerf.child(pathFile);

            // Delete the file
            desertRef.delete().then(() => {
                // File deleted successfull
                console.log("File: '" + pathFile + "' deleted successfully!");
            }).catch((error) => {
                // Uh-oh, an error occurred!
                console.error("Error deleting: " + pathFile);
            });
        }

        // Remove the selected cuadro
        let newCuadros = tempCuadros.filter( acuadro => acuadro.id !== id);

        // Save it into the the state
        this.setState({ cuadros: newCuadros });

    }

    


    handleCuadroChange(event){

        let idElement = event?.target?.id;
        let val = event?.target?.value;

        // Debug
        // console.log("event", event)

        // Sanity Check
        if(!this.state.curCuadro)
        {   
            console.error("No cuadro has been selected");
            return;
        }

        // 1. Make a shallow copy of the current cuadro
        let tmpCuadro = {...this.state.curCuadro};
    
        // 2. Replace the property you're intested in
        switch(idElement){
            case 'id_canvas_title':
                tmpCuadro.title = val;
                break;
            case 'id_sold':
                tmpCuadro.sold = event?.target?.checked;
                break;
            case 'id_canvas_author':
                tmpCuadro.author = val;
                break;
            case 'id_canvas_description_es':
                tmpCuadro.description_es = val;
                break;
            case 'id_canvas_description_en':
                tmpCuadro.description_en = val;
                break;
            case 'id_canvas_height':
                tmpCuadro.height = val;
                break;
            case 'id_canvas_width':
                tmpCuadro.width = val;
                break;
            case 'id_canvas_scalefactor':
                tmpCuadro.scalefactor = val;
                break;
            case 'id_canvas_price':
                tmpCuadro.price = val;
                break;
            case 'id_canvas_url':
                tmpCuadro.url = val;
                break;
            case 'id_canvas_material':
                tmpCuadro.material = val;
                break;
            case 'id_canvas_technique':
                tmpCuadro.technique = val;
                break;
            case 'id_canvas_wall':
                tmpCuadro.wallID = val;
                break;
            case 'id_canvas_offset':
                tmpCuadro.offset = val;
                break;
            default: break;
        }
        // 3. Update the State with the new object
        this.setState( { curCuadro: tmpCuadro},
            () => {
                // this.forceUpdate();
                this.saveChangesSelectedCuadro();
                this.forceUpdate();
                // this.saveChangesSelectedCuadro();
                // this.forceUpdate();

                // Debug
                // console.log(this.state.curCuadro)
            } );
        
    }


    saveChangesSelectedCuadro(){

        // Sanity Check: 
        if(this.state.curCuadro === null)
            return;

        // Copy the list of cuadros
        let tmpCuadros = [...this.state.cuadros];

        // Find the index of the current selected cuadro
        let index = tmpCuadros.findIndex( ( acuadro => acuadro.id === this.state.curCuadro.id ) );

        // Override the cuadro in the list with the previous section (to grab the changes)
        tmpCuadros[index] =  {...this.state.curCuadro};

        // Save the new updated list 
        this.setState({cuadros: tmpCuadros });

    }


    handleCuadroImageChange(event){

         // Sanity Check: Selected file is not empty
         if(event.target.files.length === 0)
         {
             console.error("Array empty")
             return;
         }

        // Get selected file
        let tempFile = event.target.files[0];
       
        // Sanity Check: File is bellow some limit
        if(tempFile.size/1024/1024 > 1.5) 
        {  
            alert('El tamaño del archivo es ' + (tempFile.size/1024/1024).toFixed(2) + " MB y deberia ser menos de 1.5 MB");
            event.target.value = '';
            return;
        }


        if(event.target.files && event.target.files[0]){

            let reader = new FileReader();

            reader.onload = function(ev){

                this.setState({curCuadro: {... this.state.curCuadro, 
                                        imagen: ev.target.result, 
                                        imagenData: event.target.files[0] 
                                    }
                                });

                this.saveChangesSelectedCuadro();

            }.bind(this);

            reader.readAsDataURL(event.target.files[0]);
            
        }

    }

    scrollToBottom(){
        const canvasContainer = reactDom.findDOMNode(this.canvasContainer);
        canvasContainer.scrollTop = canvasContainer.scrollHeight;
    };


    render() {
        return (
            <div>

            <AppBar position="static" color="default">
                <Tabs
                    value={this.state.tabPage}
                    onChange={this.handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                    >
                    <Tab label="General"   {... a11yProps(0)} />
                    <Tab label="Colección" {... a11yProps(1)} />
                </Tabs>
            </AppBar>

            <SwipeableViews
                axis='x'
                index={this.state.tabPage}
                onChangeIndex={this.handleTabChangeIndex}
            >
                {/*---------------- General Setting Panel ------------------- */}
                <TabPanel value={this.state.tabPage} index={0} className='tabGeneral' style={{ 'padding':'0px !important'}}>
                    <div>
                    <Grid container spacing={3}>
                        <Grid item xs={3} >
                            {/* <UploadImage  currImage={this.props.exhibit?.poster || ''} 
                                          disabled={this.state.editMode ? false : true} 
                                          ></UploadImage> */}
                            {              
                                this.state.curImage?
                                    <img src={this.state.curImage || ''}  
                                        alt="No Poster" 
                                        disabled={this.state.editMode ? false : true} 
                                        className="upload-image"/>
                                :
                                    <div className="upload-image"> Sin Poster </div>
                            }
                            <input type='file' onChange={this.handleInputChange}
                                id="selectedFile"
                                hidden
                                disableUnderline >
                            </input>
                            <Button variant='contained' 
                                    style={{'width':'100%', 'marginTop':'10px', 'height': '39.5px'}} 
                                    disabled={this.state.editMode ? false : true}
                                    onClick={ e => { 
                                          
                                        // console.log(document.getElementById('selectedFile')); 
                                        document.getElementById('selectedFile')?.click(); } }
                                    >
                                        <BackupIcon style={{'paddingRight':'5px'}} />  
                                        Subir Poster 
                            </Button>
                            
                        </Grid>
                        <Grid item xs={9}>
                            <Grid container spacing={3} style={{'padding':'1px'}} >
                                <Grid item xs={11} style={{'display':'inherit'}}>
                                    <TextField
                                        required
                                        id="id_title"
                                        label="Titulo"
                                        size="small"
                                        fullWidth
                                        margin='dense'
                                        value= {this.state.curExhibit?.title || ''} 
                                        onChange={this.myChangeHandler }
                                        disabled={this.state.editMode ? false : true}
                                    />
                                    <FormControlLabel
                                        value="top"
                                        control={<Switch id="id_active" color="primary" 
                                                    checked={this.state.activedExhibit} 
                                                    onChange={this.myChangeHandler }
                                                    disabled={this.state.editMode ? false : true} />  }
                                        label="Activo"
                                        id="id_active_parent"
                                        labelPlacement="top"
                                    />
                                    </Grid>
                                </Grid>
                            <Grid container spacing={3}>
                                <Grid item xs={3}>
                                    <KeyboardDatePicker  
                                        disabled
                                        variant="inline"
                                        format="dd/MM/yyyy"
                                        id="id_dateCreated"
                                        label="Creada"
                                        value= {this.state.curExhibit?.dateCreated?.toDate() || null}   
                                        dense="true"
                                        style={{'marginBottom':'10px'}}
                                    /> 

                                    <KeyboardDatePicker
                                        autoOk
                                        dense="true"
                                        variant="inline"
                                        format="dd/MM/yyyy"
                                        id="id_dateStart"
                                        label="Comienza"
                                        value= {this.state.dateStart} 
                                        onChange={this.handleDateStartChange}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        style={{'marginBottom':'10px'}}
                                        disabled={this.state.editMode ? false : true}
                                    /> 

                                    <KeyboardDatePicker
                                        autoOk
                                        dense="true"
                                        variant="inline"
                                        format="dd/MM/yyyy"
                                        id="id_dateEnd"
                                        label="Termina"
                                        value= {this.state.dateEnd} 
                                        onChange={this.handleDateEndChange}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        style={{'marginBottom':'10px'}}
                                        disabled={this.state.editMode ? false : true}
                                    /> 
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        required
                                        dense="true"
                                        id="id_description_es"
                                        label="Descripción (ES)"
                                        size="small"
                                        fullWidth
                                        multiline
                                        rows={9}
                                        value= {this.state.curExhibit?.description_es || ''} 
                                        onChange={this.myChangeHandler }
                                        disabled={this.state.editMode ? false : true}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        dense="true"
                                        id="id_description_en"
                                        label="Descripción (EN)"
                                        size="small"
                                        fullWidth
                                        multiline
                                        rows={9}
                                        value={this.state.curExhibit?.description_en || ''} 
                                        onChange={this.myChangeHandler }
                                        disabled={this.state.editMode ? false : true}
                                    />
                                </Grid>
                            </Grid> 
                        </Grid>
                    </Grid>
                    </div>
                </TabPanel>

                {/*------------ Exhibit's Collection Panel ----------------- */}
                <TabPanel value={this.state.tabPage} index={1} className="TabPanel" disabled={this.state.curExhibit ? false : true}>
                    <div>
                    <Grid container spacing={3} style={{'padding':'12px'}}>
                        
                        {/* LEFT SIDE: List of Canvas */}
                        <Grid item xs={4} >
                            <Toolbar className="list-cuadro-toolbar">
                                <InputLabel style={{'fontWeight': 'bold'}}>Las Obras ({this.state.cuadros?.length}): </InputLabel>
                                <IconButton edge="end" aria-label="add" onClick={e => this.addCuadro() } disabled={this.state.editMode ? false : true}> 
                                    <AddPhotoAlternateOutlinedIcon/>
                                </IconButton>
                            </Toolbar>
                            <List style={{'height':'282px', 'overflowY':'scroll', 'background':'#dce1e5'}} ref={(el) => { this.canvasContainer = el; }} >
                                {this.state.cuadros?.map( (pCuadro, index)=>{
                                    return (
                                        <ListItem key={pCuadro?.id} 
                                            button 
                                            className="list-cuadro-item" 
                                            onClick={ e => this.handleSelectCuadro(pCuadro?.id) }
                                            className={ ( pCuadro.id ===  this.state.curCuadro?.id ? 'exhibitListItemSelected':'' )}
                                            >
                                                <Typography style={{'paddingRight': '10px', 'fontWeight':'bold', 'minWidth':'35px', 'maxWidth':'35px'}}>
                                                    {index + 1}
                                                </Typography>
                                                <Box className="list-cuadro-image-container" style={{'padding':'0px'}}> 
                                                    {
                                                        pCuadro?.imagen !== '' ? 
                                                            <img src={pCuadro?.imagen}  className="list-cuadro-image" alt={pCuadro?.titulo} /> 
                                                        :
                                                            <Box className="list-cuadro-no-image">
                                                                No Image
                                                                {/* {pCuadro?.id} */}
                                                            </Box>
                                                    }
                                                    {
                                                        pCuadro?.sold ? 
                                                            <Chip label="Vendido" className="list-cuadro-sold"></Chip>
                                                        :
                                                        ''
                                                    }
                                                </Box>
                                            <ListItemText  
                                                primary={pCuadro?.title} 
                                                secondary={pCuadro?.author} 
                                            />
                                            <ListItemSecondaryAction>
                                            <Popconfirm
                                                title="Estas seguro que quieres eliminar esta exposición?"
                                                onConfirm={ e => this.deleteCuadro(pCuadro.id) }
                                                // onCancel={ }
                                                okText="Si"
                                                cancelText="No"
                                                placement="right" 
                                            >
                                                <IconButton edge="end" aria-label="delete" disabled={this.state.editMode ? false : true}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Popconfirm>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        )
                                    }
                                )}
                            </List>
                        
                            <input type='file' onChange={this.handleCuadroImageChange}
                                id="id_canvas_image_select"
                                hidden
                                disableUnderline >
                            </input>
                            <Button variant='contained' 
                                    style={{'width':'100%', 'marginTop':'10px', 'height': '39.5px'}} 
                                    disabled={ (this.state.editMode && this.state.curCuadro? false : true ) }
                                    onClick={ e => { 
                                        document.getElementById('id_canvas_image_select')?.click(); } }
                                    >
                                    <BackupIcon style={{'paddingRight':'5px'}} />  
                                    Subir Obra 
                            </Button>

                        </Grid>
                        
                        {/* RIGHT SIDE: Propertied of the selected Canvas */}
                        <Grid item xs={8} style={{'height':'430px', 'overflowY':'scroll'}} spacing={6}>
                       
                             <Grid container spacing={6}>
                                <Grid item xs={10}>
                                    <TextField
                                            required
                                            id="id_canvas_title"
                                            label="Titulo"
                                            size="small"
                                            fullWidth
                                            margin='dense'
                                            value={ this.state.curCuadro?.title || ''}
                                            onChange={ e => this.handleCuadroChange(e) }
                                            style={{'marginBottom':'20px'}}
                                            disabled={this.state.editMode ? false : true}
                                        />
                                </Grid>
                                <Grid item xs={2} style={{'textAlign':'center'}}>
                                    <InputLabel style={{'paddingTop':'15px'}}>Vendido</InputLabel>
                                    <Switch id="id_sold" color="primary" 
                                        checked={this.state.curCuadro?.sold || false} 
                                        onChange={e => this.handleCuadroChange(e) }
                                        disabled={this.state.editMode ? false : true}
                                        /> 
                                </Grid>
                            </Grid>

                            <TextField
                                    required
                                    id="id_canvas_author"
                                    label="Autor"
                                    size="small"
                                    fullWidth
                                    margin='dense'
                                    value= {this.state.curCuadro?.author || ''}
                                    onChange={e => this.handleCuadroChange(e) }
                                    disabled={this.state.editMode ? false : true}
                                    style={{'marginBottom':'20px'}}
                                />

                            {/* DATES */}
                            <Grid container spacing={4}>
                                <Grid item xs={6}>
                                    {/* description_es */}
                                    <TextField
                                        // required
                                        // dense="true"
                                        id="id_canvas_description_es"
                                        label="Descripción (ES)"
                                        size="small"
                                        fullWidth
                                        multiline
                                        rows={9}
                                        value= { this.state.curCuadro?.description_es || ''} 
                                        onChange={ e => this.handleCuadroChange(e) }
                                        disabled={this.state.editMode ? false : true}
                                        style={{'marginBottom':'20px'}}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    {/* description_en */}
                                    <TextField
                                        // required
                                        // dense="true"
                                        id="id_canvas_description_en"
                                        label="Descripción (EN)"
                                        size="small"
                                        fullWidth
                                        multiline
                                        rows={9}
                                        value= { this.state.curCuadro?.description_en || ''} 
                                        onChange={ e => this.handleCuadroChange(e) }
                                        disabled={this.state.editMode ? false : true}
                                        style={{'marginBottom':'20px'}}
                                    />
                                </Grid>
                            </Grid>
                            
                            {/* Physical Propertis: height, width, scalefactor */}
                            <Grid container spacing={4}>
                                <Grid item xs={4}>
                                    {/* height */}
                                    <TextField
                                            required
                                            id="id_canvas_height"
                                            label="Alto (cm)"
                                            size="small"
                                            fullWidth
                                            margin='dense'
                                            value= {this.state.curCuadro?.height || ''}
                                            onChange={ e => this.handleCuadroChange(e)  }
                                            disabled={this.state.editMode ? false : true}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start"><HeightIcon className="canvas_input_icon"/> </InputAdornment>,
                                            }}
                                            style={{'marginBottom':'20px'}}
                                        />
                                </Grid>
                                <Grid item xs={4}>
                                    {/* width */}
                                    <TextField
                                            required
                                            id="id_canvas_width"
                                            label="Ancho (cm)"
                                            size="small"
                                            fullWidth
                                            margin='dense'
                                            value= {this.state.curCuadro?.width || ''}
                                            onChange={ e => this.handleCuadroChange(e)  }
                                            disabled={this.state.editMode ? false : true}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start"><HeightIcon className="canvas_input_icon canvas_input_icon_90"/> </InputAdornment>,
                                            }}
                                            style={{'marginBottom':'20px'}}
                                        />
                                </Grid>
                                <Grid item xs={4}>
                                    {/* scaleFactr */}
                                    <InputLabel id="demo-simple-select-label">Factor de Escalabilidad</InputLabel>
                                    <Slider
                                        //track={false}
                                        //aria-labelledby="track-false-slider"
                                        aria-labelledby="continuous-slider"
                                        id="id_canvas_scalefactor"
                                        defaultValue={1}
                                        min={0.5}
                                        step={0.1}
                                        max={2}
                                        valueLabelDisplay="auto"
                                        style={{'marginTop':'20px'}}
                                        onChange={ (e,value) => this.handleCuadroChange({target:{id:"id_canvas_scalefactor", value: value } }) }
                                        value={ this.state.curCuadro?.scalefactor || 1}
                                        disabled={this.state.editMode ? false : true}
                                        track={false}
                                    />            
                                </Grid>
                            </Grid>    
                            
                            {/* Price and URL to the shop */}
                            <Grid container spacing={4}>
                                <Grid item xs={2}>
                                    {/* price */}
                                    <TextField
                                            required
                                            id="id_canvas_price"
                                            label="Precio"
                                            size="small"
                                            fullWidth
                                            margin='dense'
                                            value= {this.state.curCuadro?.price || ''}
                                            onChange={ e => this.handleCuadroChange(e)  }
                                            disabled={this.state.editMode ? false : true}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start"><EuroIcon className="canvas_input_icon" /> </InputAdornment>,
                                            }}
                                            style={{'marginBottom':'20px'}}
                                        />
                                </Grid>
                                <Grid item xs={10}>
                                    {/* url */}
                                    <TextField
                                        required
                                        id="id_canvas_url"
                                        label="URL a tienda"
                                        size="small"
                                        fullWidth
                                        margin='dense'
                                        type="url"
                                        value={this.state.curCuadro?.url || ''}
                                        onChange={ e => this.handleCuadroChange(e) }
                                        disabled={this.state.editMode ? false : true}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start"><LanguageIcon className="canvas_input_icon"/> </InputAdornment>,
                                          }}
                                        style={{'marginBottom':'20px'}}
                                    />
                                </Grid>
                            </Grid>


                            {/* Material and Tecnique */}
                            <Grid container spacing={4}>
                                <Grid item xs={6}>
                                    {/* material */}
                                    <InputLabel id="id_canvas_material_label">Materiales</InputLabel>
                                    <Select
                                        labelId="id_canvas_material"
                                        id="id_canvas_material"
                                        fullWidth
                                        value={ this.state.curCuadro?.material || AppEnums.MATERIALS_LIST[0].id}
                                        onChange={ (e) => this.handleCuadroChange({...e, target: {...e.target, id: "id_canvas_material"} }) }
                                        defaultValue={AppEnums.MATERIALS_LIST[0].id}
                                        disabled={this.state.editMode ? false : true}
                                        style={{'marginBottom':'20px'}}
                                    >
                                        { 
                                            AppEnums.MATERIALS_LIST.map( e => <MenuItem value={e.id}>{e.name_es}</MenuItem>)
                                        } 
                                    </Select>
                                </Grid>
                                <Grid item xs={6}>
                                    {/* technique */}
                                    <InputLabel id="id_canvas_technique_label">Técnica</InputLabel>
                                    <Select
                                        id="id_canvas_technique"
                                        fullWidth
                                        value={ this.state.curCuadro?.technique || AppEnums.TECHNIQUES_LIST[0].id}
                                        onChange={ (e) => this.handleCuadroChange({...e, target: {...e.target, id: "id_canvas_technique"} }) }
                                        defaultValue={AppEnums.TECHNIQUES_LIST[0].id}
                                        disabled={this.state.editMode ? false : true}
                                        style={{'marginBottom':'20px'}}
                                    >
                                        { 
                                            AppEnums.TECHNIQUES_LIST.map( e => <MenuItem value={e.id}>{e.name_es}</MenuItem>)
                                        } 
                                    </Select>
                                </Grid>
                            </Grid>


                            {/* Location and Offset */}
                            <Grid container spacing={4}>
                                <Grid item xs={6}>
                                    {/* wallID */}
                                    <InputLabel id="id_canvas_wall_label">
                                        Posición
                                        <Tooltip
                                            title={
                                            // <React.Fragment style={{'width': '400px'}}>
                                            <div>
                                                <Typography color="inherit"> Distribución de la Galeria </Typography>
                                                <img src='./assets/media/galeria_low.png' height="400px" width="375"></img>
                                            </div>
                                            // </React.Fragment>

                                            }
                                            arrow
                                            placement="top"
                                        >
                                            <InfoOutlinedIcon/>
                                        </Tooltip>
                                    </InputLabel>
                                    <Select
                                        labelId="id_canvas_wall"
                                        id="id_canvas_wall"
                                        fullWidth
                                        value={ this.state.curCuadro?.wallID || AppEnums.wallsList[0].wallID}
                                        onChange={ (e) => this.handleCuadroChange({...e, target: {...e.target, id: "id_canvas_wall"} }) }
                                        defaultValue={AppEnums.wallsList[0].wallID}
                                        disabled={this.state.editMode ? false : true}
                                        style={{'marginBottom':'20px'}}
                                    >
                                        { 
                                            AppEnums.wallsList.map( e => <MenuItem value={e.wallID}>{e.name}</MenuItem>)
                                        } 
                                    </Select>
                                </Grid>
                                <Grid item xs={6}>
                                    {/* offset */}
                                    <InputLabel id="demo-simple-select-label">Desplazamiento</InputLabel>
                                    <Slider
                                        track={false}
                                        aria-labelledby="track-false-slider"
                                        defaultValue={0}
                                        valueLabelDisplay="auto"
                                        min={-3}
                                        max={3}
                                        step={0.1}
                                        style={{'marginTop':'20px'}}
                                        onChange={ (e,value) => this.handleCuadroChange({target:{id:"id_canvas_offset", value: value } }) }
                                        value={ this.state.curCuadro?.offset || 0}
                                        disabled={this.state.editMode ? false : true}
                                    />
                                </Grid>
                            </Grid>
                            
                        </Grid>
                    
                    </Grid>
                    </div>
                </TabPanel>

            </SwipeableViews>
            
            <CardActions>
              <Button 
                // onClick={props.onClose} 
                    disabled={this.state.editMode ? false : true}
                    onClick={this.props.onCancelUpdate}
                    color="primary">
                    Cancelar
                </Button>
                <Button 
                    // onClick={handleSubmit} 
                    disabled={this.state.editMode ? false : true}
                    onClick={this.sendUpdate}
                    color="primary" variant="contained"> 
                    Guardar
                </Button>
            </CardActions>
           
            </div>
        )
    }
}

export default TabExhibit