import {notification } from 'antd';
import './App.css'

export function isNil(value){
    return value === undefined || value === null;
}


export function isEmpty(str) {
    return (!str || str.length === 0 );
}


export function NotifyUser(type, title, msg){
  notification[type]({
    message: title,
    description: msg,
    style: { 'zIndex': '10000' }
  });
};


export function isNumberKey(evt){
    var charCode = (evt.which) ? evt.which : evt.keyCode
    if (charCode > 31 && (charCode < 48 || charCode > 57))
        return false;
    return true;
}

export function NotifyUserSuccess(title, msg){
    NotifyUser('success', title, msg);
}

export function NotifyUserInfo(title, msg){
    NotifyUser('info', title, msg);
}

export function NotifyUserWarn(title, msg){
    NotifyUser('warning', title, msg);
}

export function NotifyUserError(title, msg){
    NotifyUser('error', title, msg);
}