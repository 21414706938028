import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Login from './Login';
import App from './App';
import { AuthProvider } from './Contexts/AuthContext';
import PrivateRoute from './PrivateRoute';
import ForgotPassword from './ForgotPassword';
import NoFoundPage from './NoFoundPage';


export default function AppMain() {


    return (

        <Router>
            <AuthProvider> 
                <Switch>
                    <Route path="/login" component={Login} />
                    <Route path="/forgot-password" component={ForgotPassword} />
                    <PrivateRoute exact path="/" component={App} />
                    <Route component={Login} />
                </Switch>
            </AuthProvider>
        </Router>

    )
}
