import {
	EventDispatcher,
	MathUtils,
	Spherical,
	Vector3
} from 'three';

const _lookDirection = new Vector3();
const _spherical     = new Spherical();
const _target        = new Vector3();
let _initialCameraHeight = 1.7;
const _changeEvent   = { type: 'change' };

const spherical      = new Spherical();

/**
 * WalkControlsDrag Class
 * 
 */
class WalkControlsDrag extends EventDispatcher  {

	constructor( object, domElement ) {
		
		super();
		
		if ( domElement === undefined ) {

			console.warn( 'THREE.WalkControls: The second parameter "domElement" is now mandatory.' );
			domElement = document;
		}

		this.object     = object;     // Camera 
		this.domElement = domElement; // DomElement of the ThreeJS Context
		this.target     = _target;    // Target Coordinates

		
		// API
		this.enabled = true;

		this.movementSpeed = 1.0;
		this.lookSpeed     = 0.005;

		this.lookVertical = true;
		this.autoForward  = false;

		this.activeLook    = true;
		this.isMouseInside = false; // This to lock the rotations when the mouse
									// is outside the context. 

		this.heightSpeed = false;
		this.heightCoef  = 1.0;
		this.heightMin   = 0.0;
		this.heightMax   = 1.0;
		this.lockHeight  = 1.65;

		this.constrainVertical = false;
		this.verticalMin       = 0;    
		this.verticalMax       = Math.PI;

		this.mouseDragOn = false;

		this.mouseWalkEnabled = false;

		// internals

		this.autoSpeedFactor = 0.0;

		this.mouseX_Start = 0;
		this.mouseY_Start = 0;
		this.mouseX_End   = 0;
		this.mouseY_End   = 0;
		this.mouseX_Delta = 0;
		this.mouseY_Delta = 0;

		this.moveForward  = false;
		this.moveBackward = false;
		this.moveLeft     = false;
		this.moveRight    = false;

		this.viewHalfX = 0;
		this.viewHalfY = 0;

		// private variables

		let lat = 0;
		let lon = 0;

		spherical.phi   = MathUtils.degToRad(95);
		spherical.thera = 0;
	

		// Get the Initial Camera Height
		_initialCameraHeight = this.object.position.y;
		this.lockHeight      = this.object.position.y;

		// Get Previous Position of the Camera
		this.prevCameraPosition = object.position.clone();


		/**
		 * Azimuth of the camera
		 * @param {*} aphi azimuth angle from the Up Vector [deg]
		 */
		this.SetAzimuth = function(aphi){
			spherical.phi = MathUtils.degToRad(aphi);
			this.update();
		}


		/**
		 * Longitude of the camera
		 * @param {*} atheta longitude angle [deg]
		 */
		this.SetLongitude = function(atheta){
			spherical.theta = MathUtils.degToRad(atheta);
			this.update();
		}


		this.setLockedHeight = function(value){
			this.lockHeight = value;
		}

		/**
		 * 
		 */
		this.handleResize = function () {

			if ( this.domElement === document ) {

				this.viewHalfX = window.innerWidth / 2;
				this.viewHalfY = window.innerHeight / 2;

			} else {

				this.viewHalfX = this.domElement.offsetWidth / 2;
				this.viewHalfY = this.domElement.offsetHeight / 2;

			}

		};


		/**
		 * 
		 * @param {*} event 
		 */
		this.onMouseDown = function ( event ) {

			if ( this.domElement !== document ) {
				this.domElement.focus();
			}

			event.preventDefault();

			// Grab the initial coordinates 
			this.mouseX_Start = event.clientX;
			this.mouseY_Start = event.clientY;

			document.body.style.cursor = 'grabbing';

			this.mouseDragOn = true;

			// //Check if the controller is active
			// if ( this.activeLook ) {

			// 	if( !this.mouseWalkEnabled ){
			// 		document.body.style.cursor = 'grabbing';
			// 		this.mouseDragOn = true;
			// 		return;
			// 	}
	
			// 	switch ( event.button ) {

			// 		case 0: this.moveForward = true; break;
			// 		case 2: this.moveBackward = true; break;

			// 	}

			// }

		};



		/**
		 * 
		 * @param {*} event 
		 */
		this.onMouseUp = function ( event ) {

			event.preventDefault();

			// if ( this.activeLook ) {

			// 	switch ( event.button ) {

			// 		case 0: this.moveForward = false; break;
			// 		case 2: this.moveBackward = false; break;

			// 	}

			// }

			document.body.style.cursor = 'grab';

			this.mouseDragOn = false;
			
		};



		/**
		 * 
		 * @param {*} event 
		 */
		this.onMouseMove = function ( event ) {

			// Sanity Check: Only when is dragging 			
			if(!this.mouseDragOn)
				return;

			document.body.style.cursor = 'grabbing';

			// Get the Mouse Coordinate End and compute the Delta
			this.mouseX_End   = event.clientX;
			this.mouseY_End   = event.clientY;
			this.mouseX_Delta = (this.mouseX_End - this.mouseX_Start) * this.lookSpeed;
			this.mouseY_Delta = (this.mouseY_End - this.mouseY_Start) * this.lookSpeed;

			// Add the increment in the speherical coordinates for rotation
			spherical.theta += this.mouseX_Delta * 2 * Math.PI / 180;
			spherical.phi   -= this.mouseY_Delta * 2 * Math.PI / 180;

			// Update the Start Position
			this.mouseX_Start = this.mouseX_End;
			this.mouseY_Start = this.mouseY_End;
		};


		/**
		 * 
		 * @param {*} event 
		 */
		this.onKeyDown = function ( event ) {

			//event.preventDefault();

			// // Sanity Check: Check if mouse is inside or no into the context
			// if ( ! this.isMouseInside ) {
			// 	return;
			// }

			if(!this.inside)
				return;

			// Sanity Check: check if the rotation is actived
			if(!this.activeLook)
				return;	

			switch ( event.code ) {

				case 'ArrowUp':
				case 'KeyW': this.moveForward = true; break;

				case 'ArrowLeft':
				case 'KeyA': this.moveLeft = true; break;

				case 'ArrowDown':
				case 'KeyS': this.moveBackward = true; break;

				case 'ArrowRight':
				case 'KeyD': this.moveRight = true; break;

				// case 'KeyR': this.moveUp = true; break;
				// case 'KeyF': this.moveDown = true; break;

				case 'KeyQ': 
					lon++;
				 	break;
				case 'KeyE': 
					lon--;
					break;
			}

		};


		/**
		 * 
		 * @param {*} event 
		 */
		this.onKeyUp = function ( event ) {

			switch ( event.code ) {

				case 'ArrowUp':
				case 'KeyW': this.moveForward = false; break;

				case 'ArrowLeft':
				case 'KeyA': this.moveLeft = false; break;

				case 'ArrowDown':
				case 'KeyS': this.moveBackward = false; break;

				case 'ArrowRight':
				case 'KeyD': this.moveRight = false; break;

				// case 'KeyR': this.moveUp = false; break;
				// case 'KeyF': this.moveDown = false; break;

			}

		};


		/**
		 * 
		 * @param {*} x 
		 * @param {*} y 
		 * @param {*} z 
		 * @returns 
		 */
		this.lookAt = function ( x, y, z ) {

			if ( x.isVector3 ) {

				_target.copy( x );

			} else {

				_target.set( x, y, z );

			}

			this.object.lookAt( _target );

			setOrientation( this );

			return this;

		};


		/**
		 * 
		 */
		this.update = function () {

			const targetPosition = new Vector3();

			return function update( delta ) {

				// Sanity Check: The control is enablecd?
				if ( this.enabled === false ) 
					return;

				//==============================================================
				// TRANSLATION
				//==============================================================

				// Backup Current Camera Position
				this.prevCameraPosition.setX(this.object.position.x);
				this.prevCameraPosition.setZ(this.object.position.z);

				if ( this.heightSpeed ) {

					const y = MathUtils.clamp( this.object.position.y, this.heightMin, this.heightMax );
					const heightDelta = y - this.heightMin;

					this.autoSpeedFactor = delta * ( heightDelta * this.heightCoef );

				} else {

					this.autoSpeedFactor = 0.0;

				}

				const actualMoveSpeed = delta * this.movementSpeed;

				if ( this.moveForward || ( this.autoForward && ! this.moveBackward ) ) 
					this.object.translateZ( - ( actualMoveSpeed + this.autoSpeedFactor ) );

				if ( this.moveBackward ) 
					this.object.translateZ( actualMoveSpeed );

				if ( this.moveLeft ) 
					this.object.translateX( - actualMoveSpeed );

				if ( this.moveRight ) 
					this.object.translateX( actualMoveSpeed );

				// Set the Camera's Height to lock it to the given height
				//this.object.position.y = _initialCameraHeight;
				this.object.position.y = this.lockHeight;
				// console.log("WalkControl: update: ", this.object.position.y, " - ", this.lockHeight)


				//==============================================================
				// ROTATION
				//==============================================================

				// let actualLookSpeed = this.lookSpeed; //* delta;

				// // Add the increment in the speherical coordinates for rotation
				// spherical.theta += (this.mouseX_Delta * actualLookSpeed) * 2 * Math.PI / 180;
				// spherical.phi   -= (this.mouseY_Delta * actualLookSpeed) * 2 * Math.PI / 180;
	
				let minPhi = MathUtils.degToRad(this.verticalMin);
				let maxPhi = MathUtils.degToRad(this.verticalMax);

				if(spherical.phi <= minPhi)
					spherical.phi = minPhi;
				if(spherical.phi >= maxPhi)
					spherical.phi = maxPhi;

				//
				spherical.makeSafe();

				//
				const position = this.object.position;

				//
				targetPosition.setFromSphericalCoords( 1, spherical.phi, Math.PI + spherical.theta ).add( position );

				//
				this.object.lookAt( targetPosition );
				this.target = targetPosition;

				//==============================================================

				// TODO Improve this and only call it when there has been a 
				// change in the control 
				this.dispatchEvent( _changeEvent );

			};

		}();


		/**
		 * 
		 */
		this.dispose = function () {

			this.domElement.removeEventListener( 'contextmenu', contextmenu );
			this.domElement.removeEventListener( 'mousedown', _onMouseDown );
			this.domElement.removeEventListener( 'mousemove', _onMouseMove );
			this.domElement.removeEventListener( 'mouseup', _onMouseUp );

			window.removeEventListener( 'keydown', _onKeyDown );
			window.removeEventListener( 'keyup', _onKeyUp );

		};


		const _onMouseMove = this.onMouseMove.bind( this );
		const _onMouseDown = this.onMouseDown.bind( this );
		const _onMouseUp   = this.onMouseUp.bind( this );
		const _onKeyDown   = this.onKeyDown.bind( this );
		const _onKeyUp     = this.onKeyUp.bind( this );

		this.domElement.addEventListener( 'contextmenu', contextmenu );
		this.domElement.addEventListener( 'mousemove', _onMouseMove );
		this.domElement.addEventListener( 'mousedown', _onMouseDown );
		this.domElement.addEventListener( 'mouseup', _onMouseUp );

		window.addEventListener( 'keydown', _onKeyDown );
		window.addEventListener( 'keyup', _onKeyUp );

		this.prevCursor = 'default';
		this.inside = false;

		// ! Testing to block the mouse when it get out of the context
		this.domElement.addEventListener('mouseleave', ()=> { 
			this.prevCursor = document.body.style.cursor;
			document.body.style.cursor = ''; //'auto !important';
			// debug
			// console.log("Leaving", this.domElement)
			this.inside = false;
		});

		this.domElement.addEventListener('mouseenter', ()=> { 
			document.body.style.cursor = this.prevCursor;
			// debug
			// console.log("Entering", this.domElement)
			this.inside = true;
		} );


		/**
		 * 
		 * @param {*} controls 
		 */
		function setOrientation( controls ) {

			const quaternion = controls.object.quaternion;

			_lookDirection.set( 0, 0, - 1 ).applyQuaternion( quaternion );
			_spherical.setFromVector3( _lookDirection );

			lat = 90 - MathUtils.radToDeg( _spherical.phi );
			lon = MathUtils.radToDeg( _spherical.theta );

		}

		this.handleResize();

		setOrientation( this );

		this.update();

	}

	


}

function contextmenu( event ) {

	event.preventDefault();

}

export { WalkControlsDrag };