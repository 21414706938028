import React, { useContext, useState, useEffect } from 'react';
import { auth } from '../firebase';


const AuthContext = React.createContext();


export function useAuth(){
    return useContext(AuthContext);
}

export function AuthProvider({children}) {

    //
    const [currentUser, setCurrentUser] = useState();
    const [loading, setLoading] = useState(true);


    /**
     * 
     * @param {*} email 
     * @param {*} password 
     * @returns 
     */
    function login(email, password){
        // Debug
        // console.log("email: ", email, "password: ", password);
        return auth.signInWithEmailAndPassword(email, password);
    }


    /**
     * 
     * @returns 
     */
    function logout(){
        return auth.signOut();
    }


    /**
     * 
     * @param {*} email 
     * @returns 
     */
    function resetPassword(email){
        return auth.sendPasswordResetEmail(email);
    }



    // Register to the Auth State Change 
    useEffect(() => {

       // Register at the first load 
       const unsubscribe =  auth.onAuthStateChanged(user => { 
            setCurrentUser(user); 
            setLoading(false);
        });

        // Unregister 
        return  unsubscribe;

    }, [])
   

    // Expose the attributes and methods 
    // to the child components
    const value = {
        currentUser, 
        login,
        logout,
        resetPassword
    }


    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    )
}
